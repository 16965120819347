.videoContainer:focus {
  outline: none;
}
.videoContainer,
.ModalReactPlayer {
  justify-content: center;
  display: flex !important;
  position: relative;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE 11 */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none; /* Firefox 64 */
  &.videoWithNoImage {
    margin-bottom: 2rem;
  }
  &.sliderFullSize {
    height: 70vh;
  }
  &.visibility-hidden {
    visibility: hidden;
  }
  .videoWrapper {
    position: relative;
  }
  iframe,
  video {
    height: 228px;
    width: 228px;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
  video::-webkit-media-controls {
    display: none !important;
  }
  video::-webkit-media-controls-enclosure {
    display: none !important;
  }
  video:focus {
    outline: none;
  }

  .playButtonContainer {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    .playButton {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      opacity: 0.6;
      z-index: 10;
      &.visiblePlayPreview {
        opacity: 0.9;
        background: url("../../style/images/play.png") center no-repeat;
      }
    }
  }

  .containerProgressBar {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    // opacity: 0.5;

    .inputSliderVideo {
      overflow: hidden;
      // width: 220px;
      width: 100%;
      -webkit-appearance: none;
      background-color: #e0e0e0;
      cursor: pointer;
      height: 4px;
    }

    .inputSliderVideo::-webkit-slider-runnable-track {
      height: 4px;
      -webkit-appearance: none;
      color: rgb(255, 255, 255);
      margin-top: -1px;
    }

    .inputSliderVideo::-webkit-slider-thumb {
      width: 1px;
      -webkit-appearance: none;
      height: 5px;
      background: #d8d8d8;
      box-shadow: -100vw 0 0 100vw rgb(52, 63, 79);
    }

    // mozilla
    .inputSliderVideo::-moz-range-track {
      height: 6px;
      -webkit-appearance: none;
      color: rgb(255, 255, 255);
    }
    .inputSliderVideo::-moz-range-thumb {
      width: 0px;
      -webkit-appearance: none;
      height: 5px;
      background: #d8d8d8;
      box-shadow: -120px 0 0 120px rgb(52, 63, 79);
    }

    .inputSliderVideo:focus {
      outline: none;
    }

    .playButtonProgressBar {
      width: 18px;
      height: 22px;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 4px;
      &.play {
        background: url("../../style/images/play1.png") center no-repeat;
        transform: rotate(0deg);
      }
      &.pause {
        width: 14px;
        margin-right: 7px;
        margin-left: 7px;
        height: 18px;
        transform: rotate(0deg);
        background: url("../../style/images/pause1.png") center no-repeat;
      }
    }

    .fullScreen {
      width: 18px;
      height: 22px;
      margin-right: 5px;
      margin-left: 5px;
      background: url("../../style/images/fullScreen.png") center no-repeat;
      &.play {
        // background: url("../../style/images/play.png") center no-repeat;
        transform: rotate(0deg);
      }
      &.pause {
        // background: url("../../style/images/pause.png") center no-repeat;
        width: 14px;
        margin-right: 7px;
        margin-left: 7px;
        height: 18px;
        transform: rotate(0deg);
        background: url("../../style/images/fullScreen.png") center no-repeat;
      }
    }
  }
}

.videoContainer::-webkit-scrollbar {
  display: none;
}

.videoContainer:hover {
  .containerProgressBar {
    visibility: visible;
  }
}

.ModalReactPlayer {
  overflow-y: hidden;
  .closeModalButton {
    top: 20px;
    right: 5px;
    @media all and (max-width: 500px) {
      top: 80px;
      right: 10px;
    }
  }
  .containerProgressBar {
    visibility: visible;
    bottom: 5rem;
    @media screen and (orientation: landscape) {
      bottom: 23px;
    }
  }
  iframe {
    height: 80vh;
    width: 100vw;
  }

  .inputSliderVideo {
    cursor: pointer;
    overflow: hidden;
    -webkit-appearance: none;
    // background-color:rgb(52,63,79);
  }

  .inputSliderVideo::-webkit-slider-runnable-track {
    height: 4px;
    -webkit-appearance: none;
    color: rgb(255, 255, 255);
    margin-top: -1px;
  }

  .inputSliderVideo::-webkit-slider-thumb {
    width: 0px;
    -webkit-appearance: none;
    height: 7px;
    background: #434343;
    box-shadow: -90vw 0 0 90vw rgb(52, 63, 79) !important;
  }

  .inputSliderVideo:focus {
    outline: none;
  }

  .playButtonContainer {
    .playButton {
      visibility: visible;
      width: 58px;
      height: 58px;
      top: 47%;
      right: 45%;
      opacity: 1;
    }
  }

  .videoplayerWrap:focus {
    outline: none;
  }
}

.ModalReactPlayer:focus {
  outline: none;
}

.videoModal:focus {
  outline: none;
}

// styles only for IE
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .videoContainer {
    overflow-y: hidden;
  }
}
