@font-face {
    font-family: 'Futura';
    src: url('./futura/FuturaBT-Book.eot');
    src: url('./futura/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
    url('./futura/FuturaBT-Book.woff2') format('woff2'),
    url('./futura/FuturaBT-Book.woff') format('woff'),
    url('./futura/FuturaBT-Book.ttf') format('truetype'),
    url('./futura/FuturaBT-Book.svg#FuturaBT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
    font-family: 'Futura';
    src: url('./futura/Futura.eot');
    src: url('./futura/Futura.eot?#iefix') format('embedded-opentype'),
    url('./futura/Futura.woff2') format('woff2'),
    url('./futura/Futura.woff') format('woff'),
    url('./futura/Futura.ttf') format('truetype'),
    url('./futura/Futura.svg#Futura') format('svg');
    font-weight: 500;
    font-style: normal;
  }
  
@font-face {
    font-family: 'Futura';
    src: url('./futura/FuturaBT-MediumCondensed.eot');
    src: url('./futura/FuturaBT-MediumCondensed.eot?#iefix') format('embedded-opentype'),
    url('./futura/FuturaBT-MediumCondensed.woff2') format('woff2'),
    url('./futura/FuturaBT-MediumCondensed.woff') format('woff'),
    url('./futura/FuturaBT-MediumCondensed.ttf') format('truetype'),
    url('./futura/FuturaBT-MediumCondensed.svg#FuturaBT-MediumCondensed') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
  }

