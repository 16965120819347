.DateShared {
    display: inline;
    // justify-content: center;
    // align-items: center;
    // padding: 0rem 1.875rem 0.5625rem 1.875rem;
    // border-bottom: 0.0625rem solid #e5e5e5;
    // margin-bottom: 0.625rem;
    margin-top: 0.75rem;
    .dateIcon {
        width: 0.75rem;
        height: 0.75rem;
        // background: url("../../style/images/date.png") center no-repeat;
        margin-right: 0.375rem;
    }
    .date {
        font-size: 0.6875rem;
        color: #757575;
        text-align: center;
    }
}