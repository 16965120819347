.slider-container {
  // .slick-track {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100%;
  // }
  // .slick-slide {
  //   display: flex !important;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100%;
  // }
  .slick-list {
    // background-color: rgb(240, 240, 240);
    //height: 22rem;
  }
  .imageNotFound {
    margin-bottom: 2rem;
  }
  &.sliderFullSize {
    margin-top: 3rem;
    .slick-list {
      // background-color: inherit;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
    height: 17rem;
    .img-slider {
      height: 73vh;
    }
    .img-slider .imageZomming {
      height: 100vh;
    }
    .close {
      right: 1rem;
      top: 5.2rem;
    }
    @media all and (min-width: 600px) {
      .img-slider {
        height: 90vh;
      }
    }
  }
  .slider-wrap {
    background-color: rgb(240, 240, 240);
  }
  .slider-section {
    // padding: 0 1.313rem;
    > div:first-child {
      overflow: visible;
    }

    .slick-next, .slick-prev {
      right: 0rem;
      z-index: 1;
      top: 47.5%;
      width: 40px;
      height: 40px;
    }

    .slick-prev {
      left: 0rem;
    }

    .slick-prev:before {
      content: "";
      background: url("../../style/images/prev.png") center no-repeat;
      display: inline-block;
      width: 15px;
      height: 18px;
    }

    .slick-next:before {
      content: "";
      background: url("../../style/images/next.png") center no-repeat;
      display: inline-block;
      width: 15px;
      height: 18px;
    }

    .slick-next:before,
    .slick-prev:before {
      font-size: 40px;
      line-height: 0;
      opacity: 1;
      color: #000000;
    }

    .slick-dots {
      bottom: 10px;
      width: 100%;
      list-style: none;
      text-align: center;
      position: relative;
      // display: flex !important;
      // align-items: center;
      overflow-x: auto;
      max-width: 75%;
      margin: 1.2rem auto 1rem;
      //justify-content: center;
      white-space: nowrap;
      li {
        margin: 0;
      }
    }

    .slick-dots::-webkit-scrollbar {
      display: none;
    }

    .slick-dots li button:before {
      font-size: 8px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
    }

    .slick-dots li {
      width: 35px;
      height: 20px;
    }
  }

  .img-slider {
    display: flex !important;
    justify-content: center;
    align-items: center;
    // background: #f2f2f4;
    // max-width: 16.25rem !important;
    // height: 16.25rem;
    // margin: auto;
    // margin-bottom: 1rem !important;
    outline: none;

    &.bgcGray {
      background-color: rgb(240, 240, 240);
    }
    &.bgc-event-catalog {	
      background-color: rgb(255, 255, 255);	
    }
    &.lookImage {
      background-color: white;
    }

    img {
      width: 100%;
      height: 25rem;
      object-fit: contain;
      @media all and (min-width: 500px) {
        object-fit: contain;
      }
      &.imgLook {
        object-fit: contain;
      }
    }
    .sliderFullSize {
      height: 73vh;
      object-fit: contain;
      @media all and (min-width: 600px) {
          height: 90vh;
      }
    }
  }

  .slick-dot {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .slick-dot:before {
    font-family: slick;
    font-size: 8px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 23px;
    height: 23px;
    text-align: center;
    opacity: 1;
    color: #000;
    -webkit-font-smoothing: antialiased;
  }


  .Bullet:before {
    content: "";
    background: url(../../style/images/bullet.svg) center no-repeat;
    top: -2px;
  }
  .VideoIcon:before {
    content: "";
    background: url(../../style/images/video.svg) center no-repeat;
    top: -2px;
    // margin: 0 0.2rem;
  }
  .LookIcon:before {
    content: "";
    background: url(../../style/images/look.svg) center no-repeat;
    top: -2px;
    margin-left: 0.2rem;
  }

  .isLastPackShotVideo {
    border-right: 1px #a3a3a3 solid;
    padding-right: 25px;
  }

  li.slick-active .Bullet:before {
    background: url(../../style/images/bulletActive.svg) center no-repeat;
  }

  li.slick-active .LookIcon:before {
    background: url(../../style/images/lookActive.svg) center no-repeat;
  }

  li.slick-active .VideoIcon:before {
    background: url(../../style/images/videoActive.svg) center no-repeat;
  }

  .zoomIn {
    background: url("../../style/images/ZoomIn.svg") center no-repeat;
    display: inline-block;
    content: "";
    width: 28px;
    height: 28px;
    position: absolute;
    background-size: contain;
    border: 0;
    right: 0;
    bottom: 0;
  }

  .relative {
    position: relative;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 0.375rem;
  top: -6rem;
  border: 0;
  width: 1.25rem;
  height: 1.25rem;
  opacity: 1;
  z-index: 5;
  background: #ffffff;
  border-radius: 100%;
  outline: 0;
  @media (min-width: 600px) {
    width: 2.5rem;
    height: 2.5rem;
  }
  &.closeWithNotProductHeader {
    // top: 0;
    @media (min-width: 1025px) {
      //  top: -65px;
    }
  }
  @media all and (max-width: 365px) {
    top: -2.375rem;
  }
}

.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: " ";
  height: 1.25rem;
  width: 0.0625rem;
  background-color: black;
}
.close:before {
  transform: translate(-50%, -50%) rotate(45deg);
  @media (min-width: 600px) {
    height: 2rem;
    width: 0.1rem;
  }

}
.close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  @media (min-width: 600px) {
    height: 2rem;
    width: 0.1rem;
  }
}
