.header {
  .headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    position: sticky;
    background: #ffffff;
    z-index: 100;
    img {
      width: 5.3125rem;
      height: 1.5rem;
    }
  }
  .top-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    text-align: center;
    margin-bottom: 2.25rem;
    .wishlist__title {
      font-family: "din", "Futura", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
      font-size: 1.875rem;
      letter-spacing: -0.016rem;
      //padding-bottom: 0.625rem;
      //margin-bottom: 0.25rem;
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      white-space: normal;
    }
    .wishlist__text {
      text-align: center;
      white-space: normal;
      font-size: 0.875rem;
      padding-top: 0.6875rem ;
      padding: 0.6875rem 0.75rem 0;
      line-height: 1.5;
      letter-spacing: normal;
      color: #141414;
    }
  }
}
