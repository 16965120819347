.footer {
    height: auto;
    padding: 0rem 0.938rem 0;
    margin-bottom: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mention {
      text-align: center;
      font-size: 0.625rem;
      color: #757575;
      line-height: 1.8;
    }
  }