.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5625rem 0;
    width: 100%;
    .title {
        font-size: 0.875rem;
        line-height: 1.57;
        color: #141414;
        text-align: center;
    }
    .name {
        font-size: 0.875rem;
        line-height: 1.57;
        color: #141414;
        margin: 0.9375rem 0;
    }
    .link {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 1.875rem;
        align-items: flex-start;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.57;
        color: #141414;
        a {
            color: inherit;
            text-decoration: inherit; 
            display: flex;
            justify-content: center;
            align-items: center;
            &.longText {
                flex-direction: column;
            }
        }
        .phone ,.email { 
            cursor: pointer;
            background: url("../../../style/images/phone.svg") center no-repeat;
            border: none;
            height: 0.875rem;
            width:  0.875rem;
            outline: none;
            display: inline-flex;
            margin-right: 0.375rem;
        }
        .email {
            background: url("../../../style/images/mail.svg") center no-repeat;
        }
        .linkText {
            text-align: center;
        }
    }
}