.loader {
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;

  &__spinner {
    display: inline-block;
    width: 5em;
    height: 5em;
    &::after {
      content: "";
      display: block;
      width: 4em;
      height: 4em;
      margin: 0.5em;
      border-radius: 50%;
      border: 0.375em solid currentColor;
      border-color: currentColor transparent currentColor transparent;
      animation: spinner-rotate 1.2s linear infinite;
    }
  }
  &__text {
      line-height: 1.5;
  }
}
@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
