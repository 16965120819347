.wishListItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 0.9375rem;
  margin: 0 0.9375rem;
  .wishListItemContainer {
    
    .wishListItem-img {
      width: 100%;
      height: 40%;
      background-color: white;
      padding: 0.7rem;     
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
      &.imageNotFound {
        background-color: rgba(226,225,223,255);
        img {
          object-fit: cover;
        }
      }
      &.imgLook {
        background-color: white;
        text-align: center;
        img {
          object-fit: cover;
          object-position: 100% 13%;
        }
      }
      &.croppingFromBottom {
        img {
          object-fit: cover;
          object-position: 100% 100%;
        }
      }

      &.croppingFromBottom {
        img {
          // width: auto;
          object-fit: cover;
          object-position: 100% 100%;
        }
      }



    }
    .wishListItem-container{
      display: flex;
      margin-bottom: 53px;
    }
    .wishListItem-details {
      margin: 1.0625rem auto 1.875rem 0.1875rem;
      padding: 0 0.7rem;
      .wishListItem-name {
        font-weight: bold;
        font-size: 0.875rem;
        margin-top: 26.3px;
      }
      .wishListItem-subtitle {
        margin: 0.1875rem 0 0.4375rem 0;
        font-size: 0.875rem;
        font-weight: normal;
        letter-spacing: normal;
      }
      .wishListItem-price {
        font-weight: bold;
        font-size: 0.9375rem;
      }
      .wishListItem-size {
        font-size: 0.9375rem;
        margin-bottom: 3px;
      }
      .wishListItem-quantity {
        font-size: 0.9375rem;
        width: 50%;
        justify-content: flex-start;
      }
      &.imgLookText {
        text-align: center;
      }
    }    
  }

  &.wishListItem__oneItem {
    display: flex;
    justify-content: center;
    
    a {
      width: 50%;     
    }
    @media all and (max-width: 600px) {
      a {
        width: 100%; 
      }
      img {
        height: 340px !important;
      }
    }
  }
}
