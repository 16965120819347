.sliderProduct {
  display: flex;
  .arrowContainer {
    cursor: pointer;
    // padding: 0 0.375rem;
    .arrow {
      border: solid black;
      border-width: 0 0.125rem 0.125rem 0;
      display: inline-block;
      padding: 0.25rem;
      opacity: 0.6;
      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-left: 0.625rem;
      }

      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        margin-right: 0.625rem;
      }
    }
  }
  .arrowContainer:focus {
    outline: none;
  }
}
